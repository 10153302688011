.card-azul {
  border-radius: 5px;
  -webkit-box-shadow: 0 1px 2.94px 0.06px rgba(4, 26, 55, 0.16);
  box-shadow: 0 1px 2.94px 0.06px rgba(4, 26, 55, 0.16);
  border: none;
  margin-bottom: 30px;
  -webkit-transition: all 0.1s ease-in-out;
  transition: all 0.1s ease-in-out;
  background: linear-gradient(45deg, #215497, #5c85ba);
  color: #fff;
  padding: 15px;
}

.card-azul:hover {
  transform: scale(1.10);
}

.botao-menu {
  -webkit-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}

.botao-menu:hover {
  /* transform: scale(1.10); */
  background-color: #5c85ba;
  border-radius: 5px;
}